.logo {
    position: fixed;
    top: 0;
    left: 0;
    padding: 20px;
}

.logo h1 {
    margin: 0;
    text-transform: uppercase;
    color: black;
    font-size: 60px;
    line-height: 60px;
    text-shadow: 6px 6px 0px white;
}
