html,
body {
    margin: 0;
    padding: 0;
    background-color: black;
    color: white;
    font-family: 'Inter', sans-serif;
}

a {
    font-family: 'Inter', sans-serif;
    color: white;
}
