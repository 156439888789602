.emailWrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 210px;
    height: 80px;
    border: white solid 2px;
    text-transform: uppercase;
}

$steps: 50;
.emailWrapper a {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-decoration: none;
    animation: textAnimation 10s infinite steps($steps);
    display: inline-block;
}

@keyframes textAnimation {
    @for $i from 1 to $steps {
        $per: 100% / $steps * $i;
        $weight: (100, 200, 300, 400, 500, 600, 700);
        $style: (normal, italic, normal, normal);
        $decor: (none, none, line-through, underline, none, none, none);
        $trans: (
            none,
            none,
            uppercase,
            lowercase,
            none,
            none,
            none,
            none,
            none,
            none
        );

        $arrayW: random(7);
        $arrayS: random(4);
        $arrayD: random(7);
        $arrayT: random(10);

        #{$per} {
            font-weight: nth($weight, $arrayW);
            font-style: nth($style, $arrayS);
            text-decoration: nth($decor, $arrayD);
            text-transform: nth($trans, $arrayT);
        }
    }
}
