.footer {
    position: fixed;
    right: 0;
    bottom: 0;
    padding: 5px;
}

.footer a {
    font-size: 20px;
    vertical-align: middle;
    text-transform: uppercase;
    text-decoration: line-through;
    cursor: pointer;
    padding-left: 5px;
}
